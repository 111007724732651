import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]

  declare tabTargets: HTMLElement[]
  declare contentTargets: HTMLElement[]

  change(event: Event) {
    event.preventDefault()

    const tab = event.currentTarget as HTMLElement
    const index = this.tabTargets.indexOf(tab)

    if (index === -1) return

    this.updateClass(this.tabTargets, index)
    this.updateClass(this.contentTargets, index)
  }

  updateClass(targets: HTMLElement[], index: number) {
    targets.forEach((target, i) => {
      target.classList.toggle("active", i === index)
    })
  }
}

