import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'

export default class extends Controller {
  static values = { slidesPerView: { type: Number, default: 1 }, options: { type: Object, default: {} } }
  static targets = ["slider", "nextButton", "prevButton", "pagination"]

  declare readonly sliderTarget: HTMLElement
  declare readonly nextButtonTarget: HTMLElement
  declare readonly prevButtonTarget: HTMLElement
  declare readonly paginationTarget: HTMLElement
  declare readonly slidesPerViewValue: number
  declare readonly optionsValue: SwiperOptions
  declare hasPaginationTarget: boolean
  declare hasNextButtonTarget: boolean
  declare hasPrevButtonTarget: boolean
  declare hasSlidesPerViewValue: boolean

  connect() {
    const defaultOptions: SwiperOptions = {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      slidesPerView: this.slidesPerViewValue,
      spaceBetween: 6,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      }
    }

    const swiperOptions: SwiperOptions = {
      ...defaultOptions,
      ...this.optionsValue,
    }

    if (this.hasPaginationTarget) {
      swiperOptions.pagination = {
        el: this.paginationTarget,
        clickable: true,
      }
    }

    if (this.hasNextButtonTarget && this.hasPrevButtonTarget) {
      swiperOptions.navigation = {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      }
    }

    new Swiper(this.sliderTarget, swiperOptions)
  }
}
