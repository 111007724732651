import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"
export default class extends Controller {
  static values = { buildingId: String }

  declare readonly buildingIdValue: string

  connect() {
    this.updateButtons()
  }

  like(event: Event) {
    event.preventDefault()

    const favoriteCookieValues = this.parseCurrentFavoriteCookies()
    if (favoriteCookieValues.includes(this.buildingIdValue)) { return }

    favoriteCookieValues.push(this.buildingIdValue)
    Cookies.set("favorite", favoriteCookieValues.join(','), { expires: 90, path: '/' })

    this.updateButtons()
  }

  unlike(event: Event) {
    event.preventDefault()

    let favoriteCookieValues = this.parseCurrentFavoriteCookies()
    favoriteCookieValues = favoriteCookieValues.filter(id => id !== this.buildingIdValue)
    Cookies.set("favorite", favoriteCookieValues.join(','), { expires: 90, path: '/' })

    this.updateButtons()
  }

  parseCurrentFavoriteCookies() {
    const favoriteCookiesRaw = Cookies.get("favorite")
    return favoriteCookiesRaw ? favoriteCookiesRaw.split(',').map(id => id.trim()) : []
  }

  showHideButtons(buttons: NodeListOf<Element>, shouldHide: boolean) {
    buttons.forEach(button => {
      button.classList.toggle("hide", shouldHide)
    })
  }

  updateButtons() {
    const favoriteCookieValues = this.parseCurrentFavoriteCookies()
    const likeButtons = document.querySelectorAll(`.js-like-button-${this.buildingIdValue}`)
    const unlikeButtons = document.querySelectorAll(`.js-unlike-button-${this.buildingIdValue}`)

    const isFavorite = favoriteCookieValues.includes(this.buildingIdValue)
    this.showHideButtons(likeButtons, isFavorite)
    this.showHideButtons(unlikeButtons, !isFavorite)
  }
}
