import { Controller } from "@hotwired/stimulus"
import { Loader } from '@googlemaps/js-api-loader'
import { get } from '@rails/request.js'

const CAPITAL_CENTER = { lat: 35.696857285971824, lng: 139.62743447462867 }

// TODO: 英語対応
export default class extends Controller {
  static values = {
    apiKey: String,
    areaGroup: String
  }
  static targets = ['map']

  // SEE: https://developers.google.com/maps/documentation/javascript?hl=ja
  initialize() {
    this.loader = new Loader({ apiKey: this.apiKeyValue, version: "3.58" })
    this.markers = []
  }

  connect() {
    this.loader.load()
      .then(async(google) => {
        const buildings = await this.loadBuildings()
        this.map = new google.maps.Map(this.mapTarget, {
          center: CAPITAL_CENTER,
          zoom: 11,
          mapId: 'SEARCH_BUILDINGS_BY_MAP'
        })
        this.displayMarkers(buildings)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  changeCenterCapital() {
    this.changeCenter(CAPITAL_CENTER)
  }

  changeCenterKansai() {
    this.changeCenter({ lat: 34.8964499059156, lng: 135.60918496152576 })
  }

  changeCenter(newCenter) {
    this.map.setCenter(newCenter)
    this.map.setZoom(11)
  }

  searchBuildings(event) {
    event.preventDefault()

    const keywordValue = event.target.querySelector('input[name="keyword"]').value
    this.loadMarkers({ keyword: keywordValue })
  }

  fitMapToBounds() {
    const bounds = new google.maps.LatLngBounds()

    this.markers.forEach((marker) => {
      bounds.extend(marker.getPosition())
    })

    if (!bounds.isEmpty()) {
      this.map.fitBounds(bounds)

      if (this.markers.length === 1) {
        this.map.setZoom(this.map.getZoom() - 5)
      }
    }
  }

  async loadBuildings(params) {
    const response = await get('/search/search', {
      headers: { 'Accept': 'application/json' },
      query: params,
      responseKind: 'json'
    })

    if (response.ok) {
      const json = await response.json
      return json.buildings
    } else {
      console.error('Request failed')
    }
  }

  async loadMarkers(params) {
    const buildings = await this.loadBuildings(params)
    this.clearMarkers()
    this.displayMarkers(buildings)

    this.fitMapToBounds()
  }

  clearMarkers() {
    this.markers.forEach((marker) => marker.setMap(null))
    this.markers = []
  }

  displayMarkers(buildings) {
    buildings.forEach((building) => {
      const { name_ja: nameJa, latitude, longitude } = building

      if (latitude && longitude) {
        const marker = new google.maps.Marker({
          position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          map: this.map
        })

        const infoWindow = new google.maps.InfoWindow({
          content: this.contentString(building),
          ariaLabel: nameJa,
          headerDisabled: true
        })

        this.markers.push(marker)

        marker.addListener("click", () => {
          if (infoWindow.isOpen) {
            infoWindow.close()
          } else {
            infoWindow.open({
              anchor: marker,
              map: this.map,
            })
          }
        })

        this.map.addListener("click", () => {
          infoWindow.close()
        })
      }
    })
  }

  contentString(building) {
    const { name_ja: nameJa, key_phrase_ja: keyPhraseJa, image_url: imageUrl, building_url_ja: buildingUrlJa } = building
    return `
      <div class="search__map-info-window">
        <a href=${buildingUrlJa}>
          <img class="search__map-image" alt=${nameJa} src=${imageUrl}>
          <div class="search__map-text">
            <h3>${nameJa}</h3>
            <p class="search__map-keyphrase">${keyPhraseJa}</p>
          </div>
        </a>
      </div>
  `
  }
}
