import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["detail", "open", "close"]

  declare readonly detailTargets: HTMLElement[]
  declare readonly openTarget: HTMLElement
  declare readonly closeTarget: HTMLElement
  declare readonly hasCloseTarget: boolean

  open(event: Event) {
    event.preventDefault()
    this.detailTargets.forEach(target => target.classList.add('show'))
    this.openTarget.classList.remove("show")

    if (this.hasCloseTarget) {
      this.closeTarget.classList.add("show")
    }
  }

  close(event: Event) {
    event.preventDefault()
    this.detailTargets.forEach(target => target.classList.remove('show'))
    this.openTarget.classList.add("show")

    if (this.hasCloseTarget) {
      this.closeTarget.classList.remove("show")
    }
  }
}
